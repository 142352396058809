/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
// import { useStaticQuery, graphql } from "gatsby"
import { IntlContextConsumer } from "gatsby-plugin-intl"

import { useSiteMetadata } from "../hooks/use-site-metadata"

import favicon16 from "../images/favicon16.png";
import favicon32 from "../images/favicon32.png";
import favicon96 from "../images/favicon96.png";

function SEO({ siteDescription, lang, meta, keywords, siteTitle }) {
  // const site = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //           author
  //         }
  //       }
  //     }
  //   `
  // )

  const { title, description, author } = useSiteMetadata()
  const metaDescription = siteDescription || description

  var titlePage

  // if (siteTitle.constructor === Array){
  //   titlePage = siteTitle[0] + " " + siteTitle[2]
  // } else {
  //   titlePage = siteTitle
  // }

  titlePage = siteTitle

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) =>
        <Helmet
          htmlAttributes={{
            lang,
          }}
          title={titlePage}
          titleTemplate={`${title}`}
          meta={[
            {
              name: `theme-color`,
              content: `#f7f5f2`,
            },
            {
              name: `msapplication-TileColor`,
              content: `#da532c`,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:creator`,
              content: author,
            },
            {
              name: `twitter:title`,
              content: title,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
          ]
            .concat(
              keywords.length > 0
                ? {
                  name: `keywords`,
                  content: keywords.join(`, `),
                }
                : []
            )
            .concat(meta)}
          link={[
            { rel: "icon", type: "image/png", sizes: "16x16", href: `/favicon-16x16.png?v=3ew6yOYxRO` },
            { rel: "icon", type: "image/png", sizes: "32x32", href: `/favicon-32x32.png?v=3ew6yOYxRO` },
            { rel: "apple-touch-icon", type: "image/png", sizes: "180x180", href: `/apple-touch-icon.png?v=3ew6yOYxRO` },
            { rel: "manifest", href: `/site.webmanifest?v=3ew6yOYxRO` },
            { rel: "mask-icon", href: `/safari-pinned-tab.svg?v=3ew6yOYxRO`,  color: `#000000` }
          ]}
        >
          <html lang={currentLocale} />
        </Helmet>  
      }
    </IntlContextConsumer>
    
  )
}


SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
