import React from "react";
// import { Link, IntlContextConsumer, changeLocale, injectIntl } from "gatsby-plugin-intl"

export default ({ lightTheme, open, ...props }) => (
    <div className="test">
        <div className={`burger-menu ${open? "open": null } ${lightTheme? "light-theme" : null}`} {...props}>
            <div className="bar1" key="b1" />
            <div className="bar2" key="b2" />
            <div className="bar3" key="b3" />
        </div>
        {/* <IntlContextConsumer>
            {({ language: currentLocale }) =>
                <div className="small-switch-language">{currentLocale}</div>
            }
        </IntlContextConsumer> */}
    </div>
);