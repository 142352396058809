/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { StaticQuery, graphql } from "gatsby"

import Logo from "./Logo"
// import "../assets/layout.css"

import Popup from "reactjs-popup";
import BurgerIcon from "./BurgerIcon";
import Menu from "./Menu";

import "../assets/bucy.scss";
// import "flexboxgrid/css/flexboxgrid.min.css";
import "react-flexbox-grid/dist/react-flexbox-grid.css";


class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showLogo: false };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    console.log(this.state.fullpage);
  }

  handleScroll() {
    var currentScrollPos = window.pageYOffset;
    var screenHeight = window.innerHeight;
    // console.log(currentScrollPos);
    if (currentScrollPos >= screenHeight) {
      // console.log(currentScrollPos);
      this.setState({ showLogo : true })
    }
    else {
      this.setState({ showLogo: false })
    };
  }

  render() {
    const { children,  clean } = this.props;
    const contentStyle = {
      background: "rgb(247,245,242)",
      width: "80%",
      border: "none"
    };




    return (
      

          <>
            {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
            {clean ?
              null
            :
              <>
                <Popup
                  modal
                  overlayStyle={{ background: "rgb(247,245,242)" }}
                  contentStyle={contentStyle}
                  closeOnDocumentClick={false}
                  className={"main-menu"}
                  lockScroll
                  trigger={open => <BurgerIcon open={open} />}
                >
                  {close => <Menu close={close} />}
                </Popup>
                {this.state.showLogo ? <Logo /> : null}
              </>
            }
            
            <div className={!clean ? null : "clean"}>
              <main>{children}</main>
            </div>
          </>
        
    )
  }
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
