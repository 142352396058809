import React from "react";
import { IntlContextConsumer, injectIntl } from "gatsby-plugin-intl"
import { Grid, Row, Col } from 'react-flexbox-grid'
import Logo from "../components/Logo"
import bgEsper from "../assets/svg/bg-esperluette.svg";

const Menu = ({ close, intl }) => (
    <>
        <div className="switch-language">
            <IntlContextConsumer>
                {({ languages, language: currentLocale }) =>
                    languages.map(language => (
                        // <Link
                        //     key={language}
                        //     to={currentLocale}
                        //     // onClick={() => changeLocale(language)}
                        //     className={currentLocale === language ? `active` : null}
                        // >
                        //     {language}
                        // </Link>
                        
                            <a href={`/${language}/`} className={currentLocale === language ? `active` : null}>{language}</a>
                        
                    ))
                }  
            </IntlContextConsumer>
        </div>
        <div className="menu">
            <ul>
            <Grid fluid>
                <Row>
                    <Col lg={6} xs={12} >

                        <li><h2 onClick={() => {window.fullpage_api.silentMoveTo("fp-presentation");close(true);}}>{intl.formatMessage({ id: "menu.item1" })}</h2></li>
                        <li><h2 onClick={() => {window.fullpage_api.silentMoveTo("fp-expertises");close(true);}}>{intl.formatMessage({ id: "menu.item2" })}</h2></li>
                        <li><h2 onClick={() => {window.fullpage_api.silentMoveTo("fp-offers-header");close(true);}}>{intl.formatMessage({ id: "menu.item3" })}</h2> 
                            <ul>
                                <li><h3 onClick={() => {window.fullpage_api.silentMoveTo("fp-intelligence-compliance");close(true);}}>{intl.formatMessage({ id: "menu.item31" })}</h3></li>
                                <li><h3 onClick={() => {window.fullpage_api.silentMoveTo("fp-business-diplomacy");close(true);}}>{intl.formatMessage({ id: "menu.item32" })}</h3></li>
                                <li><h3 onClick={() => {window.fullpage_api.silentMoveTo("fp-public-affairs-reputation");close(true);}}>{intl.formatMessage({ id: "menu.item33" })}</h3></li>
                                <li><h3 onClick={() => {window.fullpage_api.silentMoveTo("fp-sovereign-dispute-resolution");close(true);}}>{intl.formatMessage({ id: "menu.item34" })}</h3></li>
                            </ul>
                        </li>

                        {/* <li onClick={close}><Link onClick={() => window.fullpage_api.silentMoveTo("fp-expertises")}><h2>{intl.formatMessage({ id: "menu.item2" })}</h2></Link></li>
                        <li onClick={close}><Link onClick={() => window.fullpage_api.silentMoveTo("fp-offers-header")}><h2>{intl.formatMessage({ id: "menu.item3" })}</h2></Link>
                            <ul>
                                <li onClick={close}><Link onClick={() => window.fullpage_api.silentMoveTo("fp-intelligence-compliance")}><h3>{intl.formatMessage({ id: "menu.item31" })}</h3></Link></li>
                                <li onClick={close}><Link onClick={() => window.fullpage_api.silentMoveTo("fp-business-diplomacy")}><h3>{intl.formatMessage({ id: "menu.item32" })}</h3></Link></li>
                                <li onClick={close}><Link onClick={() => window.fullpage_api.silentMoveTo("fp-public-affairs-reputation")}><h3>{intl.formatMessage({ id: "menu.item33" })}</h3></Link></li>
                                <li onClick={close}><Link onClick={() => window.fullpage_api.silentMoveTo("fp-sovereign-dispute-resolution")}><h3>{intl.formatMessage({ id: "menu.item34" })}</h3></Link></li>
                            </ul>
                        </li> */}
                    </Col>
                    <Col lg={6} xs={12} >
                        <li><h2 onClick={() => {window.fullpage_api.silentMoveTo("fp-international");close(true);}}>{intl.formatMessage({ id: "menu.item4" })}</h2></li>
                        <li><h2 onClick={() => {window.fullpage_api.silentMoveTo("fp-management");close(true);}}>{intl.formatMessage({ id: "menu.item5" })}</h2></li>
                        <li><h2 onClick={() => {window.fullpage_api.silentMoveTo("fp-advisory-board");close(true);}}>{intl.formatMessage({ id: "menu.item6" })}</h2></li>
                        <li><h2 onClick={() => {window.fullpage_api.silentMoveTo("fp-at-a-glance");close(true);}}>{intl.formatMessage({ id: "menu.item7" })}</h2></li>
                        <li><h2 onClick={() => {window.fullpage_api.silentMoveTo("fp-contact");close(true);}}>{intl.formatMessage({ id: "menu.item8" })}</h2></li>
                    </Col>
                </Row>
            </Grid>
            </ul>
        </div>
        <div onClick={close}><Logo/></div>
        <img className="bg-esper" src={bgEsper} alt="" />
    </>
);

export default injectIntl(Menu)